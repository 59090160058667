import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { CartContext } from '../../../Context/CardContext'; // Asegúrate de la ruta correcta

const Aprobado = () => {
    const [compraDatos, setCompraDatos] = useState(null);
    const [comprobante, setComprobante] = useState(null);
    const [mensajeAdicional, setMensajeAdicional] = useState('');
    const [redireccionando, setRedireccionando] = useState(false);
    const { vaciarCarrito } = useContext(CartContext); // Usar el contexto para vaciar el carrito

    useEffect(() => {
        // console.log('Ejecutando useEffect en Aprobado');

        const actualizarCompra = async () => {
            const datos = localStorage.getItem('compraDatos');
            const comprobanteUrl = localStorage.getItem('comprobanteUrl');

            // console.log('Datos del localStorage:', datos);
            // console.log('URL del comprobante:', comprobanteUrl);

            if (datos) {
                const compraDatos = JSON.parse(datos);
                setCompraDatos(compraDatos);
                // console.log('Datos de compra:', compraDatos);

                if (comprobanteUrl) {
                    try {
                        const response = await fetch(comprobanteUrl);
                        const data = await response.json();

                        // console.log('Respuesta del comprobante:', data);

                        if (data.success && data.comprobante) {
                            setComprobante(data.comprobante);
                        } else {
                            console.error('Error al obtener el comprobante: Comprobante no encontrado en la respuesta.');
                        }
                    } catch (error) {
                        console.error('Error en la solicitud del comprobante:', error);
                    }
                }

                // Actualizar la compra en el backend
                try {
                    const response = await fetch('https://onloop.cl/api/actualizar_compra.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: new URLSearchParams({
                            nombre: compraDatos.nombre,
                            rut: compraDatos.rut,
                            telefono: compraDatos.telefono,
                            correo: compraDatos.correo,
                            precio_total: compraDatos.precio_total,
                            precio_comuna: compraDatos.precio_comuna,
                            total_pagar: compraDatos.total_pagar,
                            medio_pago: compraDatos.medioPago,
                            productos: JSON.stringify(compraDatos.productos),
                            direccion: compraDatos.direccion,
                            comuna: compraDatos.comuna,
                            region: compraDatos.region.nombre,
                            update_stock: true // Asegúrate de enviar este parámetro
                        }),
                    });

                    const responseData = await response.json();
                    // console.log('Respuesta de la actualización de compra:', responseData);

                    if (!response.ok || !responseData.success) {
                        console.error('Error en la actualización de compra:', response.statusText, responseData);
                    } else {
                        setMensajeAdicional('Regresarás a la página de inicio');
                        setRedireccionando(true);
                    }
                } catch (error) {
                    console.error('Error al actualizar la compra:', error);
                }
            }

            // Limpiar los datos del localStorage si no los necesitas más
            localStorage.removeItem('compraDatos');
            localStorage.removeItem('comprobanteUrl');
        };

        actualizarCompra();
    }, []); // Dependencias vacías para ejecutar solo una vez

    useEffect(() => {
        if (redireccionando) {
            // console.log('Redireccionando a la página de inicio...');
            setTimeout(() => {
                vaciarCarrito(); // Vaciar el carrito
                window.location.href = '/';
            }, 10000);
        }
    }, [redireccionando, vaciarCarrito]);

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f4f4f4'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: '30px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    maxWidth: '400px',
                    backgroundColor: '#fff'
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    ¡Compra Exitosa!
                </Typography>
                <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                    Su compra ha sido procesada exitosamente.
                </Typography>
                <Typography variant="body1" component="p" sx={{ mt: 1 }}>
                    En breve recibirá un comprobante en su correo.
                </Typography>
                {comprobante && (
                    <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                        {comprobante}
                    </Typography>
                )}
                {mensajeAdicional && (
                    <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                        {mensajeAdicional}
                    </Typography>
                )}
            </Paper>
        </Box>
    );
};

export default Aprobado;
