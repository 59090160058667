import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

const Resumen = ({ 
    carrito, 
    precioTotal, 
    costoEnvio, 
    nombre, 
    rut, 
    telefono, 
    correo, 
    direccion, 
    selectedComuna,
    selectedRegion,
    selectedRegionId
}) => {

    // Función para formatear números a pesos chilenos
    const formatoCLP = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP'
    });

    // Verificar que precioTotal sea una función que retorna un número
    const totalCompra = typeof precioTotal === 'function' ? parseInt(precioTotal(), 10) : 0;

    // Calcular el coste adicional total de envío correctamente
    const costeAdicionalTotal = carrito.reduce((total, producto) => {
        let costeAdicionalModificado = 0;

        if (Number(selectedRegionId) === 7) { // Si la región es la Metropolitana de Santiago (ID: 7)
            costeAdicionalModificado = producto.coste_adicional;

            if (costeAdicionalModificado !== 0) {
                if (producto.cantidad < 2 && costeAdicionalModificado === 2000) {
                    costeAdicionalModificado = 0;
                } else if (producto.cantidad >= 2) {
                    costeAdicionalModificado = (producto.cantidad - 1) * 2000;
                }
            }
        }

        return total + costeAdicionalModificado;
    }, 0);

    // Calcular el precio total de envío sumando el costo base más los costos adicionales
    const precioTotalEnvio = parseInt(costoEnvio, 10) + costeAdicionalTotal;

    // Calcular el total a pagar correctamente
    const totalAPagar = totalCompra + precioTotalEnvio;

    return (
        <Box sx={{ padding: { xs: 1, sm: 2 }, maxWidth: '800px', margin: 'auto' }}>
            {carrito.map((producto) => {
                let costeAdicionalModificado = 0;

                if (Number(selectedRegionId) === 7) {
                    costeAdicionalModificado = producto.coste_adicional;

                    if (costeAdicionalModificado !== 0) {
                        if (producto.cantidad < 2 && costeAdicionalModificado === 2000) {
                            costeAdicionalModificado = 0;
                        } else if (producto.cantidad >= 2) {
                            costeAdicionalModificado = (producto.cantidad - 1) * 2000;
                        }
                    }
                }

                return (
                    <Box key={producto.id} sx={{ marginBottom: 2 }}>
                        <Typography variant="h6">{producto.titulo}</Typography>
                        <Typography>Precio unitario: {formatoCLP.format(producto.precio)}</Typography>
                        <Typography>Precio total: {formatoCLP.format(producto.precio * producto.cantidad)}</Typography>
                        <Typography>Cantidad: {producto.cantidad}</Typography>
                        <Typography>Costo adicional de envio: {formatoCLP.format(costeAdicionalModificado)}</Typography>
                        <Divider sx={{ marginTop: 2 }} />
                    </Box>
                );
            })}
            <Typography variant="subtitle1">Nombre: {nombre}</Typography>
            <Typography variant="subtitle1">RUT: {rut}</Typography>
            <Typography variant="subtitle1">Teléfono: {telefono}</Typography>
            <Typography variant="subtitle1">Correo: {correo}</Typography>
            <Typography variant="subtitle1">Dirección: {direccion}</Typography>
            <Typography variant="subtitle1">Región: {selectedRegion}{/* (ID: {selectedRegionId})*/}</Typography> 
            <Typography variant="subtitle1">Comuna: {selectedComuna}</Typography>
            <Divider sx={{ marginTop: 2 }} />
            {precioTotalEnvio !== 0 || selectedRegionId === 7 ? (
                <>
                    <Typography variant="subtitle1">
                        Costo de envío: {costoEnvio === 0 && selectedRegion !== 'Metropolitana de Santiago' ? "Se debe negociar" : formatoCLP.format(costoEnvio)}
                    </Typography>
                    <Typography variant="subtitle1">Costo adicional de envío total: {formatoCLP.format(costeAdicionalTotal)}</Typography>
                </>
            ) : null}
            <Typography variant="h6">
                <b>
                    {precioTotalEnvio === 0 && selectedRegion !== 'Metropolitana de Santiago'
                        ? "El costo de envio sera negociado con el equipo de onloop dependiendo en donde se necesite el producto" 
                        : `Precio total de envío: ${formatoCLP.format(precioTotalEnvio)}`
                    }
                </b>
            </Typography>
            {precioTotalEnvio !== 0 || selectedRegion === 'Metropolitana de Santiago' ? (
                <>
            <Typography variant="h6"><b>Precio de compra: {formatoCLP.format(totalCompra)}</b></Typography>
            </>
            ) : <br></br>}
            <Typography variant="h6"><b>Total a pagar: {formatoCLP.format(totalAPagar)}</b></Typography>
        </Box>
    );
};

export default Resumen;
