import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const ConfirmarProductos = ({ 
    carrito, 
    precioTotal, 
    handleEliminarProducto, 
    setCalcularHabilitado, 
    setComunaData 
}) => {
    const [comunas, setComunas] = useState([]);
    const [comunasSel, setComunasSel] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({});
    const [selectedComuna, setSelectedComuna] = useState('');
    const [costoEnvio, setCostoEnvio] = useState(null);
    const [calcularPresionado, setCalcularPresionado] = useState(false);
    const [mensajeError, setMensajeError] = useState('');

    useEffect(() => {
        const getRegiones = async () => {
            try {
                const response = await fetch('https://onloop.cl/api/regiones.php');
                const data = await response.json();
                if (data.success) {
                    setRegiones(data.data);
                    // console.log("Regiones obtenidas:", data.data);
                } else {
                    console.error("Error al obtener las regiones:", data.msg);
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
            }
        };

        getRegiones();
    }, []);

    useEffect(() => {
        const getComunas = async () => {
            if (selectedRegion.id) {
                try {
                    const response = await fetch('https://onloop.cl/api/comunas.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: new URLSearchParams({
                            region_id: selectedRegion.id,
                        }),
                    });
                    const data = await response.json();
                    if (data.success) {
                        setComunas(data.data);
                    } else {
                        console.error("Error al obtener las comunas:", data.msg);
                    }
                } catch (error) {
                    console.error("Error en la solicitud:", error);
                }
            }
        };

        getComunas();
    }, [selectedRegion]);

    const handleRegionChange = (event) => {
        const newRegionName = event.target.value;
        const region = regiones.find(region => region.nombre === newRegionName);
        if (region) {
            setSelectedRegion(region);
            setComunasSel([]);
            setSelectedComuna('');
            setCostoEnvio(null);
            setCalcularPresionado(false);
            setCalcularHabilitado(false);
            setMensajeError('');
            // console.log("Región seleccionada:", region);
        }
    };

    const handleComunaChangeInternal = (event) => {
        const newComuna = event.target.value;
        setSelectedComuna(newComuna);
        setCostoEnvio(null);
        setCalcularPresionado(false);
        setCalcularHabilitado(false);
    };

    useEffect(() => {
        if (selectedRegion.id && comunas.length > 0) {
            const filteredComunas = comunas.filter(comuna => comuna.region_id === selectedRegion.id);
            setComunasSel(filteredComunas);
        }
    }, [selectedRegion, comunas]);

    const isComunaValid = comunasSel.some(comuna => comuna.nombre === selectedComuna);

    const verificarStockYVigencia = async () => {
        try {
            const response = await fetch('https://onloop.cl/api/verificar_stock.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    productos: JSON.stringify(carrito.map(producto => ({
                        id_producto: producto.id,
                        cantidad: producto.cantidad
                    })))
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Error en la verificación de stock:`, response.statusText, errorText);
                throw new Error('Error en la solicitud: ' + response.statusText);
            }

            const result = await response.json();
            if (result.success) {
                // console.log('Todos los productos están disponibles y vigentes.');
                return true;
            } else {
                if (result.resultados && Array.isArray(result.resultados)) {
                    const errores = result.resultados.map(producto => 
                        `El producto "${producto.titulo}" ${producto.razon}.`
                    ).join('\n');
                    setMensajeError(errores);
                }
                return false;
            }
        } catch (error) {
            console.error('Error al verificar el stock:', error.message);
            setMensajeError('Error de conexión al verificar el stock');
            return false;
        }
    };

    const handleCalcular = async () => {
        const stockValido = await verificarStockYVigencia();

        if (!stockValido) {
            return;
        }

        // console.log("ID de la región seleccionada:", selectedRegion.id);
        // console.log("Precio total:", precioTotal());

        const categoriasPermitidas = ['Sillas', 'Juguetes', 'Mascotas', 'Basureros'];
        const todasCategoriasValidas = carrito.every(producto => categoriasPermitidas.includes(producto.categoria.toLowerCase()));
        const totalPrecio = parseFloat(precioTotal());

        let nuevoCostoEnvio;
        if (Number(selectedRegion.id) === 7 && totalPrecio > 25990 && todasCategoriasValidas) {
            nuevoCostoEnvio = 0;
        } else if (Number(selectedRegion.id) !== 7) {
            nuevoCostoEnvio = 0;
        } else {
            const comunaSeleccionada = comunasSel.find(comuna => comuna.nombre === selectedComuna);
            if (comunaSeleccionada) {
                nuevoCostoEnvio = totalPrecio > 55000
                    ? comunaSeleccionada.segundo_precio
                    : comunaSeleccionada.primer_precio;
                nuevoCostoEnvio = nuevoCostoEnvio === 0 ? 0 : nuevoCostoEnvio;
            }
        }

        const costoEnvioFinal = nuevoCostoEnvio || 0;

        setCostoEnvio(costoEnvioFinal);
        setCalcularPresionado(true);
        setCalcularHabilitado(true);

        setComunaData({
            selectedRegion,
            selectedComuna,
            costoEnvio: costoEnvioFinal
        });
    };

    const handleEliminarProductoInternal = (productoId) => {
        handleEliminarProducto(productoId);
        setSelectedComuna(''); // Vaciar la selección de comuna
        setSelectedRegion(''); // Vaciar la selección de comuna
        setMensajeError(''); // Limpiar el mensaje de error
        setCalcularHabilitado(false);
    };

    const formatoCLP = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP'
    });

    return (
        <Box sx={{ padding: { xs: 2, sm: 3 }, maxWidth: '800px', margin: 'auto' }}>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <EmojiObjectsIcon sx={{ marginRight: 3 }} />
                        <Typography>
                            Para agregar productos a tu canasta, presiona el botón 'Seguir comprando' ubicado al inicio de la página. Serás enviado a la tienda nuevamente.
                        </Typography>
                    </Box>
                </Grid>

                {carrito.length > 0 ? (
                    <>
                        {carrito.map((producto) => {
                            let costeAdicionalModificado = 0;

                            if (Number(selectedRegion.id) === 7) {
                                costeAdicionalModificado = producto.coste_adicional;

                                if (costeAdicionalModificado !== 0) {
                                    if (producto.cantidad < 2 && costeAdicionalModificado === 2000) {
                                        costeAdicionalModificado = 0;
                                    } else if (producto.cantidad >= 2) {
                                        costeAdicionalModificado = (producto.cantidad - 1) * 2000;
                                    }
                                }
                            }

                            return (
                                <Grid item xs={12} key={producto.id}>
                                    <Box sx={{ marginBottom: 3 }}>
                                        <Typography variant="h6">{producto.titulo}</Typography>
                                        <Typography>Precio unitario: {formatoCLP.format(producto.precio)}</Typography>
                                        <Typography>Precio total: {formatoCLP.format(producto.precio * producto.cantidad)}</Typography>
                                        <Typography>Cantidad: {producto.cantidad}</Typography>
                                        <Typography>Categoría: {producto.categoria}</Typography>
                                        <Typography>Costo adicional de envío: {formatoCLP.format(costeAdicionalModificado)}</Typography>
                                        <Button 
                                            variant="contained" 
                                            color="secondary" 
                                            onClick={() => handleEliminarProductoInternal(producto.id)}
                                            sx={{ marginTop: 1 }}
                                        >
                                            Quitar unidad
                                        </Button>
                                        <Divider sx={{ marginTop: 2 }} />
                                    </Box>
                                </Grid>
                            );
                        })}

                        <Divider sx={{ marginTop: 2 }} />

                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="region-label">Selecciona tu Región</InputLabel>
                                <Select
                                    labelId="region-label"
                                    label="Selecciona tu Región"
                                    value={selectedRegion.nombre || ''}
                                    onChange={handleRegionChange}
                                    required
                                >
                                    <MenuItem value="" disabled>
                                        Seleccione región
                                    </MenuItem>
                                    {regiones.map((region) => (
                                        <MenuItem key={region.id} value={region.nombre}>
                                            {region.nombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedRegion.id}>
                                <InputLabel id="comuna-label">Selecciona tu Comuna</InputLabel>
                                <Select
                                    labelId="comuna-label"
                                    label="Selecciona tu Comuna"
                                    value={isComunaValid ? selectedComuna : ''}
                                    onChange={handleComunaChangeInternal}
                                    required
                                >
                                    <MenuItem value="" disabled>
                                        Seleccione comuna
                                    </MenuItem>
                                    {comunasSel.map((comuna) => (
                                        <MenuItem key={comuna.id} value={comuna.nombre}>
                                            {comuna.nombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                sx={{ mt: 2 }}
                                variant="contained"
                                onClick={handleCalcular}
                                disabled={!isComunaValid}
                            >
                                Calcular
                            </Button>

                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ marginTop: 2 }}>
                                Precio total: {formatoCLP.format(parseInt(precioTotal(), 10))}
                            </Typography>
                            {calcularPresionado && (
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    Costo de envío: {Number(selectedRegion.id) !== 7 && costoEnvio === 0 ? 'Se debe negociar' : formatoCLP.format(costoEnvio)}
                                </Typography>
                            )}
                        </Grid>
                            {mensajeError && (
                        <Grid item xs={12}>
                            <Typography color="error">{mensajeError}</Typography>
                        </Grid>
                )}
                    </>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="h5">Agrega productos al carrito para continuar</Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ConfirmarProductos;
