import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Fallido = () => {
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f4f4f4'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: '30px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    maxWidth: '400px',
                    backgroundColor: '#fff'
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    ¡Error en la Compra!
                </Typography>
                <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                    Hubo un problema al procesar su compra.
                </Typography>
                <Typography variant="body1" component="p" sx={{ mt: 1 }}>
                    Intente nuevamente más tarde o contacte a soporte.
                </Typography>
            </Paper>
        </Box>
    );
};

export default Fallido;
