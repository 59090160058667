import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CartContext } from '../../Context/CardContext';

const SeleccionMedioPago = ({
    medioPago,
    mediosPago,
    handleMedioPagoChange,
    carrito,
    precioTotal,
    costoEnvio,
    nombre,
    rut,
    telefono,
    correo,
    direccion,
    selectedComuna,
    selectedRegion
}) => {
    const { vaciarCarrito } = useContext(CartContext);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeAdicional, setMensajeAdicional] = useState('');

    const verificarStockYVigencia = async () => {
        try {
            const response = await fetch('https://onloop.cl/api/verificar_stock.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    productos: JSON.stringify(carrito.map(producto => ({
                        id_producto: producto.id,
                        cantidad: producto.cantidad
                    })))
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Error en la verificación de stock:`, response.statusText, errorText);
                throw new Error('Error en la solicitud: ' + response.statusText);
            }

            const result = await response.json();
            if (result.success) {
                // console.log('Todos los productos están disponibles y vigentes.');
                return true;
            } else {
                if (result.resultados && Array.isArray(result.resultados)) {
                    const errores = result.resultados.map(producto => 
                        `El producto "${producto.titulo}" ${producto.razon}.`
                    ).join('\n');
                    setMensajeError(errores);  // Actualizar el mensaje de error con los detalles
                }
                return false;
            }
        } catch (error) {
            console.error('Error al verificar el stock:', error.message);
            setMensajeError('Error de conexión al verificar el stock');
            return false;
        }
    };

    const handlePayClick = async () => {
        const stockValido = await verificarStockYVigencia();

        if (!stockValido) {
            setMensajeAdicional('Regresarás a la página de inicio');
            setTimeout(() => {
                vaciarCarrito();
                window.location.href = '/';
            }, 10000);
            return;
        }

        const productos = carrito.map(producto => {
            let costeAdicionalModificado = producto.coste_adicional;

            if (producto.cantidad === 1 && producto.coste_adicional > 0) {
                costeAdicionalModificado = 0;
            } else if (producto.cantidad >= 2 && producto.coste_adicional > 0) {
                costeAdicionalModificado = (producto.cantidad - 1) * 2000;
            }

            return {
                id_producto: producto.id,
                titulo: producto.titulo,
                precio: producto.precio,
                cantidad: producto.cantidad,
                coste_adicional: costeAdicionalModificado
            };
        });

        const totalCosteAdicional = productos.reduce((total, producto) => total + producto.coste_adicional, 0);
        const totalAPagar = parseInt(precioTotal(), 10) + parseInt(costoEnvio, 10) + totalCosteAdicional;

        // console.log('Datos para enviar:', {
        //     nombre,
        //     rut,
        //     telefono,
        //     correo,
        //     direccion,
        //     comuna: selectedComuna,
        //     region: { id: selectedRegion.id, nombre: selectedRegion.nombre },
        //     precio_total: precioTotal(),
        //     precio_comuna: costoEnvio,
        //     costo_adicional: totalCosteAdicional,
        //     total_pagar: totalAPagar,
        //     productos: productos,
        //     medioPago: medioPago,
        // });

        // Guardar datos en localStorage
        localStorage.setItem('compraDatos', JSON.stringify({
            nombre,
            rut,
            telefono,
            correo,
            direccion,
            comuna: selectedComuna,
            region: { id: selectedRegion.id, nombre: selectedRegion.nombre },
            precio_total: precioTotal(),
            precio_comuna: costoEnvio,
            costo_adicional: totalCosteAdicional,
            total_pagar: totalAPagar,
            productos: productos,
            medioPago: medioPago,
        }));

        // Redirigir a la página Aprobado
        if (medioPago === 'Mercado Pago') {
            try {
                const response = await fetch('https://onloop.cl/api/mercadoPago/mercado_pago.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        title: 'Compra Onloop',
                        price: totalAPagar,
                        quantity: 1,
                    }),
                });

                const result = await response.json();

                if (result.init_point) {
                    window.location.href = result.init_point;
                } else {
                    setMensajeError('Error al generar la preferencia de pago.');
                }
            } catch (error) {
                setMensajeError('Error de conexión con Mercado Pago.');
                console.error('Error:', error);
            }
        } else {
            // Guardar URL del comprobante en localStorage
            const comprobanteUrl = selectedRegion.nombre === 'Metropolitana de Santiago'
                ? 'https://onloop.cl/api/comprobante_completo.php'
                : 'https://onloop.cl/api/comprobante_incompleto.php';

            localStorage.setItem('comprobanteUrl', comprobanteUrl);
            window.location.href = '/confirmacion-compra';  // Redirige a la página Aprobado
        }
    };

    return (
        <React.Fragment>
            <Typography variant="h2" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                Seleccione su medio de pago
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 2,
                    mx: 'auto',
                    maxWidth: 400,
                    width: '100%'
                }}
            >
                <Select
                    value={medioPago}
                    onChange={handleMedioPagoChange}
                    displayEmpty
                    fullWidth
                    sx={{ minWidth: 200, mb: 2 }}
                >
                    <MenuItem value="" disabled>
                        Seleccione medio de pago
                    </MenuItem>
                    {mediosPago.map((medio) => (
                        <MenuItem key={medio.id} value={medio.nombre}>
                            {medio.nombre}
                        </MenuItem>
                    ))}
                </Select>
                <Button
                    onClick={handlePayClick}
                    disabled={!medioPago}
                    variant="contained"
                    sx={{ width: '100%' }}
                >
                    Pagar
                </Button>
                {mensajeError && (
                    <Typography variant="h6" color="error" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                        {mensajeError}
                    </Typography>
                )}
                {mensajeAdicional && (
                    <Typography variant="h6" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                        {mensajeAdicional}
                    </Typography>
                )}
            </Box>
        </React.Fragment>
    );
};

export default SeleccionMedioPago;
