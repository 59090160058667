import React, { useContext } from 'react';
import { CartContext } from '../../Context/CardContext';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@mui/material'; // Asegúrate de importar Typography desde MUI

// Estilo para el contenedor principal del carrito
const Container = styled.div`
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;  // Reduce el padding en pantallas pequeñas
  }
`;

// Estilo para cada tarjeta de producto en el carrito
const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;  // Reduce el padding en pantallas pequeñas
  }
`;

// Estilo para los botones en el carrito
const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #ff4d4d;  // Color de fondo del botón
  color: white;  // Color del texto del botón
  border: none;
  border-radius: 5px;
  cursor: pointer;  // Cambia el cursor al pasar sobre el botón
  margin-right: 10px;
  &:hover {
    background-color: #e03e3e;  // Color de fondo al pasar el cursor sobre el botón
  }
  @media (max-width: 768px) {
    padding: 0.3rem 0.7rem;  // Reduce el padding en pantallas pequeñas
    margin-right: 0;
  }
`;

// Estilo para los enlaces que se ven como botones
const ButtonLink = styled(Link)`
  text-decoration: none;  // Elimina el subrayado del enlace
  flex-grow: 1;  // Hace que el enlace crezca para ocupar espacio disponible
`;

// Estilo para el contenedor de los botones de acción
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    button, a {
      margin-bottom: 10px;  // Añade espacio entre botones y enlaces en pantallas pequeñas
      flex-grow: 1;  // Hace que los botones y enlaces crezcan para ocupar espacio disponible
      display: flex;
      justify-content: center;
    }
    button:last-child, a:last-child {
      margin-bottom: 0;  // Elimina el margen inferior del último botón o enlace
    }
  }
`;

// Componente para mostrar el carrito de compras
const Carrito = ({ toggleDrawer }) => {
  // Obtiene los valores del contexto del carrito
  const { carrito, precioTotal, vaciarCarrito, eliminarProducto } = useContext(CartContext);

  // Función para formatear los precios
  const formatPrice = (price) => {
    return price.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  };

  return (
    <Container>
      {carrito.length > 0 ? (  // Si hay productos en el carrito
        <>
          {carrito.map((producto) => (  // Mapea los productos en el carrito
            <ProductCard key={producto.id}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: '1.5rem',
                  fontFamily: 'Outfit, sans-serif',
                  fontWeight: 'bold',
                  marginBottom: '1rem', // Agrega margen inferior para separar del contenido siguiente
                }}
              >
                {producto.titulo}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontFamily: 'Outfit, sans-serif', 
                  fontWeight: 400, 
                  fontSize: '0.875rem' 
                }}
              >
                Precio total: {formatPrice(producto.precio * producto.cantidad)}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontFamily: 'Outfit, sans-serif', 
                  fontWeight: 400, 
                  fontSize: '0.875rem',
                  marginBottom: '1rem' // Agrega margen inferior para separar del botón de eliminar
                }}
              >
                Cant: {producto.cantidad}
              </Typography>
              <Button 
                onClick={() => eliminarProducto(producto.id)} 
              >
                Eliminar
              </Button>
            </ProductCard>
          ))}
          <Typography variant="h6" sx={{ fontFamily: 'Outfit, sans-serif', fontWeight: 'bold', marginTop: '1rem' }}>
            Sub total: {formatPrice(precioTotal())}
          </Typography>
          <ButtonContainer>
            <Button onClick={vaciarCarrito}>Vaciar carrito</Button>
            <ButtonLink to="/confirmacion">
              <Button onClick={toggleDrawer(false)}>Comprar</Button>
            </ButtonLink>
          </ButtonContainer>
        </>
      ) : (
        <Typography variant="h6" sx={{ fontFamily: 'Outfit, sans-serif', fontWeight: 'bold' }}>
          El carrito está vacío
        </Typography>
      )}
    </Container>
  );
};

export default Carrito;
