// Función para validar el RUT chileno
export const validarRut = (rut) => {
    if (!rut || typeof rut !== 'string') return false;

    // Eliminar puntos, guiones y convertir todo a mayúsculas
    const cleanRut = rut.replace(/[^0-9kK]/g, '').toUpperCase();

    // Verificar si tiene al menos dos caracteres (número base + dígito verificador)
    if (cleanRut.length < 2) return false;

    // Obtener el número base y el dígito verificador
    const cuerpo = cleanRut.slice(0, -1); // Parte numérica del RUT
    const dv = cleanRut.slice(-1); // Dígito verificador (último carácter)

    // Validar que el cuerpo sea un número
    if (!/^\d+$/.test(cuerpo)) return false;

    // Calcular el dígito verificador esperado usando el algoritmo del módulo 11
    let suma = 0;
    let multiplicador = 2;

    for (let i = cuerpo.length - 1; i >= 0; i--) {
        suma += parseInt(cuerpo[i]) * multiplicador;
        multiplicador = multiplicador < 7 ? multiplicador + 1 : 2;
    }

    const mod11 = 11 - (suma % 11);
    const dvEsperado = mod11 === 11 ? '0' : mod11 === 10 ? 'K' : mod11.toString();

    // Comparar el dígito verificador ingresado con el calculado
    return dv === dvEsperado;
};
